import React from 'react'
import {ThemedLoadingIndicator} from 'components/loading-indicator'
import {Meta} from './shared/meta'

export const Loading = () => {
  return (
    <>
      <Meta />
      <ThemedLoadingIndicator />
    </>
  )
}
