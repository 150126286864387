import memoize from 'lodash/memoize'

const isClient = typeof window != 'undefined'

export const metaKeyLabel = ({short}: {short?: boolean} = {short: true}) => {
  return isAppleOs() ? (short ? '&#8984;' : 'cmd') : 'ctrl'
}

export const isAppleOs = memoize(() => {
  return isClient ? /Mac|iP(hone|[oa]d)/.test(navigator.platform) : false
})

export const isMacOs = memoize(() => {
  return isClient ? /Mac/.test(navigator.platform) : false
})

export const isElectron = memoize(() => {
  if (isClient) {
    return /Electron/i.test(navigator.userAgent)
  }

  return false
})
