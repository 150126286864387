import React, {useEffect, useState} from 'react'
import {parseCookies} from 'nookies'
import Home from './home'
import {isElectron} from 'plugins/user-agent'
import {DynamicApp} from 'app/dynamic-app'
import {Loading} from 'site-v2/loading'

type IndexState = 'loading' | 'home' | 'app'

export default function Index() {
  const [state, setState] = useState<IndexState>('loading')

  useEffect(() => {
    const cookies = parseCookies(null)
    const {indexApp} = cookies
    const loadApp = indexApp || isElectron()

    setState(loadApp ? 'app' : 'home')
  }, [])

  switch (state) {
    case 'home':
      return <Home />
    case 'app':
      return <DynamicApp />
    default:
      return <Loading />
  }
}
