import React from 'react'
import {LoadingIndicator} from './loading-indicator'

export const ThemedLoadingIndicator: React.FC = () => {
  return (
    <div className="absolute inset-0 themed-loading-indicator">
      <LoadingIndicator />
    </div>
  )
}
